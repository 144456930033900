import {format, parseISO} from 'date-fns'
import {enGB, es, fr, sl} from 'date-fns/locale'
import {IntlModule} from "@/store";
import {ELocale} from "@/store/modules/Intl";

type Locale = 'es' | 'fr' | 'enGB' | 'sl';

export default function (date: string, formatStr = 'PP') {
    const locales: Record<Locale, any> = {es, fr, enGB, sl};

    let locale: Locale = IntlModule.locale as unknown as Locale;

    if (IntlModule.locale === ELocale.en) {
        locale = 'enGB';
    }

    if (IntlModule.locale === ELocale.fr_PPRO || IntlModule.locale === ELocale.fr_EKW) {
        locale = 'fr';
    }

    return format(parseISO(date), formatStr, {
        locale: locales[locale]
    });
}