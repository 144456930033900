import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {EModules} from "../modules";
import {AuthModule} from "@/store";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {
    EGeolocationResponseState, TGeolocationDeviceStateResponse,
    TGeolocationPhone,
    TGeolocationPhoneFromDevice,
    TGeolocationStateResponse,
    TGeolocationStatus
} from "@/services/Geolocation/interfaces";
import GeolocationService from "@/services/Geolocation";
import {
    getDeviceInfos,
    getDeviceLocationState,
    getPermissionsState,
    getVersion,
    refreshGeofences
} from "@/helpers/domains/flutter-connectors";
import {isWebViewMobileApp} from "@/helpers/domains/device";

@Module({name: EModules.GEOLOCATION})
class Geolocation extends VuexModule {
    private _status: TGeolocationStatus | undefined = undefined;
    private _deviceLocationState: TGeolocationDeviceStateResponse | undefined = undefined;
    private _phones: TGeolocationPhone[] = [];
    private _currentPhone: TGeolocationPhoneFromDevice | undefined = undefined;
    private _currentPermissions: TGeolocationStateResponse[] = [];
    private _isFlutterGeolocReady = false;

    get currentPhone(): TGeolocationPhoneFromDevice | undefined {
        return this._currentPhone;
    }

    get phoneLocationServiceEnabled(): boolean {
        if (isWebViewMobileApp(window) && this._deviceLocationState) {
            return this._deviceLocationState?.phoneLocationServiceEnabled;
        }
        return true;
    }

    get phones(): TGeolocationPhone[] {
        return this._phones;
    }

    get isGeolocationWarning(): boolean {
        const isCurrentPhoneInPhones = this.isCurrentPhoneTracked;
        const isGeolocationActivated = this._status?.activatedByUser ?? false;
        const isDeviceLocationServicesOn = this.phoneLocationServiceEnabled

        return isCurrentPhoneInPhones && isGeolocationActivated && !isDeviceLocationServicesOn
    }

    get isFlutterGeolocReady(): boolean {
        if (isWebViewMobileApp(window)) {
            return this._isFlutterGeolocReady
        }
        return true;
    }

    get isGeolocReady(): boolean {
        if (this._status) {
            return this.isFlutterGeolocReady && this._status.isGeolocReady
        }
        return false;
    }

    get permissions(): TGeolocationStateResponse[] {
        return this._currentPermissions
    }

    get status(): TGeolocationStatus | undefined {
        return this._status
    }

    get isCurrentPhoneTracked(): boolean {
        return this._phones.some((phone: TGeolocationPhone) => phone.deviceId === this._currentPhone?.deviceId)
    }

    get isPermissionNeeded(): boolean {
        return this._currentPermissions.some(permission => permission.state !== EGeolocationResponseState.granted)
    }

    /**
     * Mutations
     */
    @Mutation
    public _setPhones(phones: TGeolocationPhone[]): void {
        this._phones = phones;
    }

    @Mutation
    public _setStatus(status: TGeolocationStatus): void {
        this._status = status;
    }

    @Mutation
    public _setDeviceLocationState(state: TGeolocationDeviceStateResponse): void {
        this._deviceLocationState = state;
    }

    @Mutation
    public _setPermissions(permisisons: TGeolocationStateResponse[]): void {
        this._currentPermissions = permisisons;
    }

    @Mutation
    public _setCurrentPhone(phone: TGeolocationPhoneFromDevice): void {
        this._currentPhone = phone;
    }

    @Mutation
    public _setIsFlutterGeolocReady(isReady: boolean): void {
        this._isFlutterGeolocReady = isReady;
    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public async getPhones(): Promise<TGeolocationPhone[] | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return GeolocationService.getPhones(getCurrentSiteIdFromCookie(user))
                .then(phones => {
                    this.context.commit("_setPhones", phones);
                    return phones;
                })
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public async postPhone(body: TGeolocationPhone): Promise<TGeolocationPhone | void> {
        const user = AuthModule.userOrNull

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        try {
            return GeolocationService.postGeolocationPhone(getCurrentSiteIdFromCookie(user), body)
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @Action({rawError: true})
    public async putPhone(body: TGeolocationPhone): Promise<TGeolocationPhone | void> {
        const user = AuthModule.userOrNull

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        try {
            return GeolocationService.putGeolocationPhone(getCurrentSiteIdFromCookie(user), body)
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @Action({rawError: true})
    public async deletePhone(deviceId: string): Promise<void> {
        const user = AuthModule.userOrNull

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        try {
            return GeolocationService.deleteGeolocationPhone(getCurrentSiteIdFromCookie(user), deviceId)
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @Action({rawError: true})
    public async getStatus(): Promise<TGeolocationStatus | void> {
        const user = AuthModule.userOrNull

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        try {
            return GeolocationService.getGeolocationStatus(getCurrentSiteIdFromCookie(user)).then(status => {
                this.context.commit("_setStatus", status);
                return status;
            })
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @Action({rawError: true})
    public async putStatus(body: TGeolocationStatus): Promise<TGeolocationStatus | void> {
        const user = AuthModule.userOrNull

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        try {
            return GeolocationService.putGeolocationStatus(getCurrentSiteIdFromCookie(user), body).then(status => {
                this.context.commit("_setStatus", status);
                return status;
            })
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @Action({rawError: true})
    public async refreshGeofences(): Promise<void> {
        refreshGeofences()
    }

    @Action({rawError: true})
    public async retrievePhoneDatas(): Promise<void> {
        await this.context.dispatch('getPermissions')
        await this.context.dispatch('getStatus')
        await this.context.dispatch('getPhones')
        await this.context.dispatch('getDeviceInfo')
    }

    @Action({rawError: true})
    public async getPermissions(): Promise<void> {
        getPermissionsState().then(permissions => {
                this.context.commit("_setPermissions", permissions)
            }
        )
    }

    @Action({rawError: true})
    public async getDeviceInfo(): Promise<void> {
        getDeviceInfos().then(infos => {
                this.context.commit("_setCurrentPhone", infos)
            }
        )
    }

    @Action({rawError: true})
    public async getDeviceLocationState(): Promise<void> {
        getDeviceLocationState().then(state => {
                this.context.commit("_setDeviceLocationState", state)
            }
        )
    }

    @Action({rawError: true})
    public async getVersion(): Promise<void> {
        getVersion()
            .then(result => this.context.commit("_setIsFlutterGeolocReady", !!result?.version))
            .catch(() => this.context.commit("_setIsFlutterGeolocReady", false))
    }
}

export default Geolocation;
