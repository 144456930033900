import {DateUtils, useDate} from "@/helpers/dates/dateUtils";

const _timer = (datetime: DateUtils, callback: CallableFunction, params: any[] = [], notFirstTime = false) => {
    const now = useDate();
    const delay = Math.min(datetime.diff(now.toDate(), 'millisecond'), 86400000);

    if (delay <= 0) {
        if (notFirstTime) {
            callback(...params);
        }
    } else {
        setTimeout(() => {
            _timer(datetime, callback, params, true);
        }, delay, params);
    }
};

const timer = (datetime: DateUtils | Date | string | number, callback: CallableFunction, ...params: any[]) => {
    const parsedDatetime = datetime instanceof DateUtils ? datetime : useDate(datetime);
    _timer(parsedDatetime, callback, params);
};

export default timer;