import moment from "moment";

export const timeToNumber = (time: string) => moment(time, [moment.ISO_8601, 'HH:mm']);

export const diffTime = (timeA: moment.Moment | string, timeB: moment.Moment | string) => {
    if (typeof timeA === 'string') {
        timeA = timeToNumber(timeA);
    }
    if (typeof timeB === 'string') {
        timeB = timeToNumber(timeB);
    }

    const duration = moment.duration(timeA.diff(timeB));

    return Math.trunc(duration.asHours()) + Math.trunc(duration.asMinutes() % 60) / 100;
};