import {ContractModule, SwitchConsoModule} from "@/store";

export const updateSwitchConso = async (val: boolean) => {
  if (!val) {
    if (!ContractModule.contractsList.length) {
      return await ContractModule.getContractsList()
        .then(() => {
          SwitchConsoModule._setSwitchType(val)
          return true
        })
        .catch(() => {
          SwitchConsoModule._setSwitchType(true)
          return false
        })
        .finally(() => {
          SwitchConsoModule._setSwitchType(true)
        })
    }
    SwitchConsoModule._setSwitchType(val)
  }
  SwitchConsoModule._setSwitchType(val)
  return true
}