export enum EInputType {
    /**
     * Input is a text.
     */
    Text = "text",

    /**
     * Input is an email.
     */
    Email = 'email',

    /**
     * Input is textarea.
     */
    Textarea = "textarea",

    /**
     * Input is number.
     */
    NumberInput = "number",

    /**
     * Input is password.
     */
    Password = "password",
}

export type TInput = {
    /**
     * is input required.
     */
    autoComplete?: string;

    /**
     * is input required.
     */
    inputRequired?: boolean;

    /**
     * Input's type.
     */
    inputType?: EInputType;

    /**
     * id
     */
    id?: string;

    /**
     * label
     */
    label?: string;

    /**
     * icon
     */
    icon?: string;

    /**
     * placeholder
     */
    placeholder?: string;

    /**
     * inputValue
     */
    value: string | number;

    defaultValue: string;

    /**
     * Model to bind input with form.
     */
    model?: any;

    /**
     * Error rendered.
     */
    error?: string;

    /**
     * Success rendered.
     */
    success?: string;

    maxlength?: number;

    min?: number;

    max?: number;

    disabled?: boolean;

    hasValue?: boolean;

    allowClear?: boolean;

    minRows?: number;

    maxRows?: number;

    dataTestId?: string;
};
