enum ERoutes {
    /**
     * Generic Home route for both authenticated mode or not.
     */
    HOME = "/",

    /**
     * Login route. use byAPIto redirect.
     */
    LOGIN = HOME,

    /**
     * Forgot password page to send an email.
     */
    FORGOT_PASSWORD = "/forgot-password",

    /**
     * Page redirected when new password or onboarding password token is invalid.
     */
    RESET_EMAIL_TOKEN = '/reset-email-token',

    /**
     * Forgot password new password page.
     */
    NEW_PASSWORD = "/new-password",

    /**
     * First connection page.
     */
    FIRST_CONNECTION = "/first-connection",

    /**
     * Route for Consumption's informations.
     */
    CONSO = "/conso",
    CONSO_DETAIL = "conso-detail",
    /**
     * Add-on for detailed repartition
     */
    CONSO_REPARTITION_DETAIL = "repartition-detail",

    /**
     * Route for heating appliances informations.
     */
    HEATING = "/heating",

    /**
     * Route for program informations.
     */
    PROGRAM = "/program",

    /*
     * profile pages
     */
    PROFILE = '/profile',
    /*
     * profile sub pages
     */
    CONTACT = '/contact',

    DIAGNOSTIC = '/diagnostic',

    MY_ACCOUNT = '/my-account',

    CONTRACT = '/contract',

    SECURITY = '/security',

    HOUSING = '/housing',

    LEGAL_INFORMATION = '/legal-information',

    GCU = '/gcu',

    MANAGE_CHOICES = '/manage-choices',

    FAQ = '/faq',

    ADVICES = '/advices',

    ADVICE = '/advice',

    CONTACT_NOT_AUTH = '/contact-help',

    /**
     * All Add-on that can be use by any route.
     */

    /**
     * Add-on for edition's routes.
     */
    ADDON_EDIT = 'edit',
    /**
     * Add-on for edition's routes.
     */
    ADDON_CREATE = 'create',

    /**
     * Add-on for planning's routes.
     */
    ADDON_PLANNING = 'planning',

    /**
     * Add-on for duration edition's routes.
     */
    ADDON_DURATION = 'duration',

    /*
     * /range
     */
    ADDON_RANGE = 'range',

    /**
     * All Params that can be use by any route.
     */

    /**
     * Param for routes using id.
     */
    PARAM_ID = ':id',

    PARAM_ADVICE = ':advice',

    /**
     * Param for routes using id appliance.
     */
    PARAM_APPLIANCE = ':idAppliance',
    /**
     * Param for routes using day.
     */
    PARAM_DAY = ':day',
    /**
     * Param for routes using day.
     */
    PARAM_RANGE = ':range',

    /**
     * Params for route using Devices status id
     */
    PARAM_DEVICE_ID = ':csApplianceId',

    /**
     * Conso's routes name.
     */
    CONSO_HOUR = 'hour',
    CONSO_DAY = 'day',
    CONSO_MONTH = 'month',
    CONSO_LIVE = 'live',
    CONSO_YEAR = 'year',
    CONSO_WEEK = 'week',
}

export default ERoutes;
