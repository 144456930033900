import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {EModules} from "../modules";
import {
    aggregatedWeekFormat,
    aggregatedYearFormat,
    EAggregationAlias,
    EAggregationType,
    TBreakdown,
    TConsoAggregated,
    TConsoSavings,
    TConsumptionFullData,
    TConsumptionFullDataParams,
    TSummaryConsumption,
} from "@/services/Conso/interfaces";
import ConsoService from "@/services/Conso";
import {TSite} from "@/services/Site/interfaces";
import {AuthModule} from "@/store";
import ERoutes from "@/router/routes";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import Cookie from "@/helpers/cookie";
import {ECookieNames} from "@/helpers/cookie/interfaces";
import {useDate} from "@/helpers/dates/dateUtils";

type TGetRepartition = { siteId: TSite['id'], dates: string[], type: string | null, skipLoading?: boolean };
type TGetConsoSavings = { siteId: TSite['id'], date: string, type: string | null, skipLoading?: boolean };
type TGetRealTimeConso = { siteId: TSite['id'], mode: string, numPoints: number };

type TGX<T> = {
    requests: {
        [key: string]: Promise<T | void>;
    };

    responses: {
        [key: string]: T | undefined;
    };
};

@Module({name: EModules.CONSO})
class Conso extends VuexModule {
    /**
     * State
     */
    private _summaryConsumption: TGX<TSummaryConsumption[]> = {requests: {}, responses: {}};

    private _consumption: TGX<TConsoAggregated> = {requests: {}, responses: {}};

    private _repartition: TGX<TBreakdown[]> = {requests: {}, responses: {}};

    private _consoSavings: TGX<TConsoSavings> = {requests: {}, responses: {}};

    private _realTimeConso: TConsoAggregated | undefined = undefined;

    private _consumptionFullData: TGX<TConsumptionFullData> = {requests: {}, responses: {}}

    /**
     * Getters
     */
    get consumption() {
        return this._consumption;
    }

    get summaryConsumption() {
        return this._summaryConsumption;
    }

    get repartition() {
        return this._repartition;
    }

    get consoSavings() {
        return this._consoSavings;
    }

    get realTimeConso() {
        return this._realTimeConso;
    }

    get consumptionFullData() {
        return this._consumptionFullData;
    }

    /**
     * Mutations
     */
    @Mutation
    public _setRealTimeConso(realTimeConso: TConsoAggregated): void {
        this._realTimeConso = realTimeConso;
    }

    @Mutation
    public _resetRealTimeConso(): void {
        this._realTimeConso = undefined;
    }

    @Mutation
    public _setConsumptionCacheTtl(ttl: number): void {
        //set cookie to expire after 30 minutes
        /*TODO create Getter for this cookie and add more granulometry (for past period : year -1, month -1 etc.)*/
        Cookie.set(ECookieNames.CONSUMPTION_CACHE_TTL, new Date().toDateString(), {expires: useDate(new Date()).add(ttl, 'minutes').toDate()})
    }

    @Mutation
    public _invalidateConsumptionCacheTtl(): void {
        Cookie.delete(ECookieNames.CONSUMPTION_CACHE_TTL)
        this._consumptionFullData.responses = {}
    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public invalidateConsumptionCacheTtl() {
        this.context.commit('_invalidateConsumptionCacheTtl')
    }

    @Action({rawError: true})
    public getRepartition({siteId, dates, type, skipLoading = false}: TGetRepartition): Promise<TBreakdown[] | void> {
        const user = AuthModule.userOrNull;
        const key: string = dates![0];

        if (user) {
            if (key in this._repartition.responses) {
                return Promise.resolve(this._repartition!.responses[key]);
            } else if (key in this._repartition.requests) {
                return new Promise((resolve) => {
                    const keyInterval = setInterval(() => {
                        if (key in this._repartition.responses) {
                            clearInterval(keyInterval);
                            resolve(this._repartition.responses[key]!);
                        }
                    }, 100);
                });
            }

            let fn = 'Summary';
            if (type == ERoutes.CONSO_WEEK) {
                fn = 'Weekly';
            } else if (type == ERoutes.CONSO_YEAR) {
                fn = 'Annual';
            } else if (type === ERoutes.CONSO_MONTH) {
                fn = 'Monthly';
            }

            this._repartition!.responses[key] = []
            return this._repartition!.requests[key] = (ConsoService as any)[`get${fn}Repartition`](siteId, dates, skipLoading)
                .then((res: TBreakdown[]) => {
                    delete this._repartition!.requests[key];
                    this._repartition!.responses[key] = res;
                    let length = Object.keys(this._repartition!.responses).length
                    while (length > 4) {
                        const farthestDate = Conso.extractFarthestDate(Object.keys(this._repartition!.responses), key);
                        delete this._repartition!.responses[farthestDate];
                        length -= 1;
                    }

                    return res;
                }).catch((e: any) => {
                    throw e;
                })
        }

        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getConsoSavings({siteId, date, type, skipLoading = false}: TGetConsoSavings): Promise<TConsoSavings | void> {
        const user = AuthModule.userOrNull;
        const key: string = date;

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        if (key in this._consoSavings.responses) {
            return Promise.resolve(this._consoSavings!.responses[key])
        } else if (key in this._consoSavings.requests) {
            return new Promise((resolve) => {
                const keyInterval = setInterval(() => {
                    if (key in this._consoSavings.responses) {
                        clearInterval(keyInterval);
                        resolve(this._consoSavings.responses[key]!);
                    }
                }, 100);
            });
        }

        let fn = 'Month';
        if (type === ERoutes.CONSO_YEAR) {
            fn = 'Year';
        }

        return this._consoSavings.requests[key] = (ConsoService as any)[`get${fn}ConsoSavings`](siteId, date, skipLoading)
            .then((res: TConsoSavings) => {
                delete this._consoSavings.requests[key];
                this._consoSavings.responses[key] = res;
                let length = Object.keys(this._consoSavings.responses).length;

                while (length > 3) {
                    const farthestDate = Conso.extractFarthestDate(Object.keys(this._consoSavings.responses), key);
                    delete this._consoSavings.responses[farthestDate];
                    length--;
                }

                return res;
            }).catch((e: any) => {
                throw e;
            })
    }

    @Action({rawError: true})
    public getRealTimeConso({siteId, mode, numPoints}: TGetRealTimeConso): Promise<TConsoAggregated | void> {
        const user = AuthModule.userOrNull;

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        return ConsoService.getRealTimeConso(siteId, mode, numPoints)
            .then((res: TConsoAggregated | void) => {
                if (this.realTimeConso) {
                    const lastItem = res!.consumptions[res!.consumptions.length - 1];

                    if (this.realTimeConso.consumptions.length >= 10) {
                        this.realTimeConso!.consumptions.shift();
                        this.realTimeConso!.consumptions.push(lastItem);
                    } else {
                        this.realTimeConso!.consumptions.push(lastItem);
                    }
                } else {
                    this.context.commit('_setRealTimeConso', res!);
                    this.realTimeConso!.consumptions = this.realTimeConso!.consumptions.slice(Math.max(this.realTimeConso!.consumptions.length - 10, 1));
                }

                return res;
            })
            .catch(e => {
                throw e;
            })
    }

    @Action({rawError: true})
    public stopModulation(): Promise<boolean | void> {
        const user = AuthModule.userOrNull;

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        return ConsoService.stopModulation(getCurrentSiteIdFromCookie(user)).then(() => {
            return true;
        }).catch(e => {
            throw e;
        })
    }

    @Action({rawError: true})
    public getConsumptionFullData({
                                      period,
                                      dates,
                                      forceRequest = false
                                  }: TConsumptionFullDataParams): Promise<TConsumptionFullData | void> {
        const user = AuthModule.userOrNull;
        const key: string = period === EAggregationAlias.Years ? EAggregationAlias.Years : dates;

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        if (Cookie.isExpired(ECookieNames.CONSUMPTION_CACHE_TTL)) {
            this.context.commit('_invalidateConsumptionCacheTtl')
        }

        if (this._consumptionFullData!.responses[key] && !forceRequest) {
            return Promise.resolve(this._consumptionFullData!.responses[key]);
        } else {
            if (period === EAggregationAlias.Years) {
                return this._consumptionFullData.requests[EAggregationAlias.Years] = ConsoService.getMultiYearRepartition(
                    getCurrentSiteIdFromCookie(user),
                    dates
                ).then((data) => {
                    this._consumptionFullData.responses[key] = data!;
                    delete this._consumptionFullData.requests[key];
                    this.context.commit('_setConsumptionCacheTtl', 1)

                    return data!;
                }).catch(e => {
                    throw e;
                })

            } else {
                return this._consumptionFullData.requests[key] = ConsoService.getConsumptionFullData(
                    getCurrentSiteIdFromCookie(user),
                    period,
                    dates as string
                ).then((data) => {
                    this._consumptionFullData.responses[key] = data!;
                    delete this._consumptionFullData.requests[key];
                    this.context.commit('_setConsumptionCacheTtl', 1)

                    return data;
                }).catch(e => {
                    throw e;
                })
            }
        }
    }

    private static extractFarthestDate(cachedDates: string[], key: string, interval = 4) {
        let date = key;
        let aggregation: EAggregationType | null = null;
        if (date.includes('_')) {
            const keyFragmented = key.split('_');
            date = keyFragmented[0];
            aggregation = (keyFragmented[1] + '_' + keyFragmented[2]) as EAggregationType;
        }

        let res = "";
        let units = 'months';
        const current = useDate(date);
        if (useDate(date, aggregatedYearFormat).isValid()) {
            units = 'years';
        } else if (useDate(date, aggregatedWeekFormat).isValid()) {
            units = 'weeks';
        }

        cachedDates.forEach((el) => {
            let elCurrentDate = el;
            let elAggregation = null;
            if (el.includes('_')) {
                elCurrentDate = el.split('_')[0];
                elAggregation = el.split('_')[1] + '_' + el.split('_')[2];
            }

            const loopedEl = useDate(elCurrentDate).toDate();
            const diff = Math.abs(current.diff(loopedEl, units));

            if (diff >= interval && diff !== 12) {
                res = el;
            }

            if (elAggregation && aggregation) {
                if (elAggregation !== aggregation) {
                    res = el;
                }
            }
        })

        return res;
    }
}

export default Conso;