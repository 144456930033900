export type TCookieOptions = {
    /**
     * Number of millisecond or date before expiration
     */
    expires?: number | string | Date;

    /**
     * cookie's path, defaults '/'.
     */
    path?: string;

    /**
     * cookie is secure ?
     */
    secure?: boolean;

    /**
     * cookie is use only on HTTP request ?
     */
    httpOnly?: boolean;

    /**
     * cookie's domain, this defaults to the host portion of the current document location.
     */
    domain?: string;

    /**
     * cookie's domain
     */
    "max-age"?: number;

    /**
     * Prevents the browser from sending this Cookie along with cross-site requests.
     */
    samesite?: "lax" | "strict" | "none";
};

export enum ECookieNames {
    CONSUMPTION_CACHE_TTL = '_consumptionCache',
}

export type TCookie = { [key: string]: string };
