import {EApplianceProgType, EApplianceType} from "@/services/Appliances/interfaces";

export const applianceProgType = (progType: EApplianceProgType): string => {
    switch (progType) {
        case EApplianceProgType.Program:
            return 'appliance_progType.user';
        case EApplianceProgType.Quick:
            return 'appliance_progType.quick';
        case EApplianceProgType.Manual:
            return 'appliance_progType.manual';
        case EApplianceProgType.Default:
            return 'appliance_progType.default';
    }
}

export const applianceTypeName = (applianceType: EApplianceType): string => {
    switch (applianceType) {
        case EApplianceType.Heater:
            return 'appliance_type.heater';
        case EApplianceType.WaterHeater:
            return 'appliance_type.water_heater';
        case EApplianceType.HeatingFloor:
            return 'appliance_type.heating_floor';
        case EApplianceType.Other:
            return 'appliance_type.other'
        case EApplianceType.AirConditioning:
            return 'appliance_type.air_conditioning';
        case EApplianceType.ReversibleAirConditioning:
            return 'appliance_type.reversible_air_conditioning';
        case EApplianceType.HeatPump:
            return 'appliance_type.heat_pump'
    }
}