/**
 * List of modules' namaspace.
 */
export enum EModules {
  /**
   * Router's module
   */
  ROUTER = "Router",

  /**
   * Authentification's module
   */
  AUTH = "Auth",

  /**
   * Internationalization's module
   */
  INTL = "Intl",

  /**
   * Heating's module (cache filled during the first time on Heating page)
   */
  HEATING = 'Heating',

  /**
   * Program's module (cache filled during the first time on Welcome page)
   */
  PROGRAM = 'Program',

  /**
   * Appliances' module (cache filled during the first time on Welcome page)
   */
  APPLIANCES = 'Appliances',

  /**
   * Conso's module (cache filled during the first time on Conso page)
   */
  CONSO = "Conso",

  /**
   * Conso's module (cache filled during the first time on Housing page)
   */
  HOUSING = 'Housing',

  /**
   * Contract's module (cache filled during the first time on Contract page)
   */
  CONTRACT = 'Contract',

  /**
   * Site's module (cache filled during the first time on menu)
   */
  SITE = 'Site',

  /**
   * Objective's module (cache filled during the first time on Objective modal)
   */
  OBJECTIVE = 'Objective',

  /**
   * Consent's module (stode datas about user's concent for access).
   */
  CONSENT = 'Consent',

  /**
   * Switch conso's module (cache filled using session storage for manage Switch on conso)
   */
  SWITCH_CONSO = 'SwitchConso',

  /**
   * Theme module (cache filled when application is loading to get theme)
   */
  THEME = 'Theme',

  NOTIFICATION = 'Notification',

  /**
   * Auto diagnosis module for appliance status
   */
  DIAGNOSTIC = 'Diagnostic',

  /**
   * Request loader module (use to store API's resquest state)
   */
  REQUEST_LOADER = 'RequestLoader',

  ID_APPLICATION = 'IdApplication',

  /**
   * Geolocation feature specific module
   */
  GEOLOCATION = 'Geolocation',
}
