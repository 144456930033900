import {TRepartitionCategories} from "@/services/Conso/interfaces";
import {fromWhToKwh} from "@/helpers/constants/unit";
import {format, isAfter, isBefore} from "date-fns";
import {ContractModule} from "@/store";

export const getSubcategoriesTotalConsoByType = (subcategories: TRepartitionCategories[], type: boolean): number => {
    return subcategories.reduce((_total, sub) => _total +(type ? fromWhToKwh(sub.totalConsumptionInWh || 0) : sub.totalConsumptionInCurrency || 0), 0);
}

export const getContractFromDate = (date: string | null | undefined) => {
    if (ContractModule.contractsList.length === 1) {
        return ContractModule.contractsList[0];
    } else {
        date = date ?? format(new Date(), 'MM-dd-yyyy');

        return ContractModule.contractsList.find(c => {
            return isBefore(Date.parse(c.startDate ?? '01-01-1970'), Date.parse(date!)) && isAfter(c.endDate ? Date.parse(c.endDate) : new Date(), Date.parse(date!))
        })
    }
}