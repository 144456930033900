/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from "axios";

import {AuthModule, RequestLoaderModule} from "@/store";
import router from "@/router";
import ERoutes from "@/router/routes";

import Cookie from "../cookie";

import IAPI, {TAuthBasicCallback, TAuthTokenCallback} from "./interfaces";

export const tokenCookieName = "__token";
export const enedisDateCookieName = "__enedisDate";
export const enedisCounterCookieName = "__enedisCounter";
export const contractNotifCookieName = "__contractNotif";
export const currentSiteIdCookieName = "__currentSiteId";
export const maxConnectionBeforeShowPopin = 3;
export const refreshTokenCookieName = "__refreshToken";
export const dontAskAgainProgReminderCookie = "__progReminder"
export const dontAskAgainTemperatureControlReminderCookie = "__temperatureControlReminder"
export const dontAskAgainDefaultContract = "__defaultContractReminder"

const API: IAPI = axios.create({
  baseURL: process.env.VUE_APP_REMOTE_SERVER_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

let g_interceptorsRequest = API.interceptors.request.use((config) => {
  if (config.skipLoading !== true) {
    RequestLoaderModule.addRequest();
  }
  return config;
});

API.interceptors.response.use(response => {
  if (response.config.skipLoading !== true) {
    RequestLoaderModule.removeRequest();
  }
  return response;
}, error => {
  if (error.config.skipLoading !== true) {
    RequestLoaderModule.removeRequest();
  }
  return Promise.reject(error);
});

API.setLocale = (
  locale,
  { localeVariableName, onTransformLocaleName = (locale) => locale }
) => {
  if (g_interceptorsRequest !== -1) {
    API.interceptors.request.eject(g_interceptorsRequest);
  }
  g_interceptorsRequest = API.interceptors.request.use((config) => {
    RequestLoaderModule.addRequest();
    config.params = {
      ...config.params,
      [localeVariableName]: onTransformLocaleName(locale),
    };
    return config;
  });
};

API.initRefreshToken = ({
  refreshTokenUri,
  onRefreshToken,
  refreshTokenField = "refresh_token",
  tokenExpirationField,
  tokenHTTPCodeError = 401,
}) => {
  API.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const refreshToken = Cookie.get(refreshTokenCookieName);

      if (error.response.status === tokenHTTPCodeError) {
        if (
          !refreshTokenUri ||
          originalRequest.url.indexOf(refreshTokenUri) !== -1 ||
          !refreshToken
        ) {
          AuthModule.logout();
          router.push(ERoutes.LOGIN);
        } else if (!originalRequest._retry) {
          originalRequest._retry = true;
          return API.post(refreshTokenUri, {
            [refreshTokenField]: refreshToken,
          })
            .then(({ data }) => onRefreshToken(data))
            .then(({ token, refreshToken }) => {
              API.setAuthToken!(token, tokenExpirationField);
              API.setRefreshToken!(refreshToken, tokenExpirationField);
            });
        }
      }
      return Promise.reject(error);
    }
  );
};

API.getTokenFromCookie = () => Cookie.get(tokenCookieName);

API.setRefreshToken = token =>
  Cookie.set(refreshTokenCookieName, token);

API.setAuthBasic = ((username, password) => {
  API.defaults.auth = { username, password };
}) as TAuthBasicCallback;

API.forgetAuthBasic = () => {
  delete API.defaults.auth;
};

API.setAuthToken = (token => {
  Cookie.set(tokenCookieName, token, {
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  });
  API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}) as TAuthTokenCallback;

API.forgetAuthToken = () => {
  Cookie.delete(tokenCookieName);
  Cookie.delete(refreshTokenCookieName);
  delete API.defaults.headers.common["Authorization"];
};

API.forgetAuth = () => {
  API.forgetAuthBasic!();
  API.forgetAuthToken!();
};

export default API;
