import {isWebViewMobileApp} from "@/helpers/domains/device";
import {
    EGeolocationRequests, EGeolocationRequestType,
    EGeolocationResponseType, TGeolocationDeviceStateResponse,
    TGeolocationDeviceInfoResponse,
    TGeolocationStateResponse, TGeolocationVersionResponse
} from "@/services/Geolocation/interfaces";

const setToken = (token: string | null, siteId: number | undefined) => {
    if (isWebViewMobileApp(window) && token && siteId) {
        return window.flutter_inappwebview.callHandler(EGeolocationRequestType.voltalis, {
            type: 'setToken',
            token,
            siteId: siteId
        })
    }
}
const refreshGeofences = () => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EGeolocationRequestType.geolocation, {type: EGeolocationRequests.refreshGeofences})
    }
}

const getDeviceInfos = async (): Promise<TGeolocationDeviceInfoResponse | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EGeolocationRequestType.geolocation, {type: EGeolocationRequests.getDeviceInfo})
    }
}

const getVersion = async (): Promise<TGeolocationVersionResponse | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EGeolocationRequestType.geolocation, {type: EGeolocationRequests.getVersion})
    }
}

const getPermissionsState = async (): Promise<TGeolocationStateResponse[] | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EGeolocationRequestType.geolocation, {type: EGeolocationRequests.getPermissionsState});
    }
}

const getDeviceLocationState = async (): Promise<TGeolocationDeviceStateResponse | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EGeolocationRequestType.geolocation, {type: EGeolocationRequests.getLocalisationState});
    }
}

const openAppSettings = async (): Promise<TGeolocationStateResponse[] | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EGeolocationRequestType.geolocation, {type: EGeolocationRequests.openAppSettings})
    }
}

const requirePermission = async (locationType: EGeolocationResponseType): Promise<TGeolocationStateResponse | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EGeolocationRequestType.geolocation, {
            type: EGeolocationRequests.requirePermission,
            permission: locationType
        })
    }
}

export {
    setToken,
    refreshGeofences,
    getDeviceInfos,
    getPermissionsState,
    openAppSettings,
    requirePermission,
    getVersion,
    getDeviceLocationState
}
