import {TUser} from "@/services/Auth/interfaces";
import {currentSiteIdCookieName} from "@/helpers/api";
import Cookie from "@/helpers/cookie";
import {TSite} from "@/services/Site/interfaces";
import {AuthModule} from "@/store";

// TODO: refactor me inside a dedicated service
export const getCurrentSiteIdFromCookie = (user: TUser) => {
  const cookieName = `${currentSiteIdCookieName}${user.id}`;
  const currentSiteIdCookie = Cookie.get(cookieName);

  return currentSiteIdCookie ? parseInt(currentSiteIdCookie) as TSite['id']  : user.defaultSite.id;
}

export const getCurrentSiteObjectFromCookie = (siteId: TSite['id']) => {
  const userOtherSites = AuthModule.user.otherSites;
  const currentSite = userOtherSites.find(site => site.id === siteId);

  return currentSite ? currentSite : AuthModule.user.defaultSite;
}