import {createApp} from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";

import '@/chart';
import i18n from './i18n';

import moment from 'moment';

moment.updateLocale('fr', {
    week: {
        dow: 1
    }
});

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueGtag, {
        config: {
            id: "UA-47640629-2"
        }
    }, router)
    .directive('nl2br', el => {
        el.innerHTML = el.innerHTML.replace(/\r?\n/g, '<br />');
    })


Sentry.init({
    app,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({router}),
    ],
});

app.mount("#app")