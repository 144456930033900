import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {AuthModule} from "..";

import {TSite, TSites, TUpdateSiteNameParams} from "@/services/Site/interfaces";
import SiteService from "@/services/Site";

import {EModules} from "../modules";

@Module({name: EModules.SITE})
class Site extends VuexModule {
    private _sites: TSites | undefined = undefined;

    /**
     * Getters
     */
    get sites(): TSites {
        return this._sites!;
    }

    /**
     * Mutations
     */

    @Mutation
    public _setSites(sites: TSites): void {
        this._sites = sites;
    }

    /**
     * Actions
     */

    @Action({rawError: true})
    public getSites() {
        const user = AuthModule.userOrNull;

        if (user) {
            return (
                this.sites !== undefined ? Promise.resolve(this.sites) :
                    SiteService.getSites()
                        .then(sites => {
                            this.context.commit("_setSites", sites);
                            return sites as TSite[];
                        })
                        .catch(e => {
                            console.error(e);
                            throw e;
                        })
            );
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public updateSite(siteId: TSite['id']) {
        const user = AuthModule.userOrNull;

        if (user) {
            return SiteService.updateSite(siteId)
                .then(site => {
                    const oldSite = AuthModule.user.defaultSite;

                    AuthModule.changeDefaultSite(site);
                    this.context.commit("_setSites", this.sites.map(_site => {
                        if (_site.id === oldSite.id) {
                            _site.default = false;
                        } else if (_site.id === site.id) {
                            _site.default = true;
                        }

                        return _site;
                    }));
                    return site;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    updateSiteName({id, name}: TUpdateSiteNameParams) {
        const user = AuthModule.userOrNull;

        if (user) {
            return SiteService.updateSiteName(id, name);
        }

        return Promise.reject(new Error('Error 403: You must be connected !'));
    }
}

export default Site;
