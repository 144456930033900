import {IntlModule} from "@/store";
import {ELocale} from "@/store/modules/Intl";

export const formatNumber = (myNumber: number, decimal = 2) => (IntlModule.locale === ELocale.fr) ? myNumber.toFixed(decimal).replace(".",",") : myNumber.toFixed(decimal);

export const formatNumberWithAllDecimal = (myNumber: number) => (IntlModule.locale === ELocale.fr) ? myNumber.toString().replace(".",",") : myNumber.toString();

export const digitalFormat = (num: number | string, decimal = 1): any => {
    if (typeof num !== 'number') {
        num = parseFloat(num);
    }
    const res = num.toLocaleString(undefined, {maximumFractionDigits: decimal});

    if (IntlModule.locale?.startsWith(ELocale.fr)) {
        return res.replace(".", ",");
    }
    return res.replace(",",".");
}

export const roundNumber = (number: number, precision = 2) => {
    const roundPrecision = Math.pow(10, precision);

    return Math.round((number + Number.EPSILON) * roundPrecision) / roundPrecision;
};

export const roundNumber2 = (number: number) => roundNumber(number, 2);

export const numberSpaceSeparator = (n: number, fixedDecimal: number | undefined = undefined) => (fixedDecimal ? n.toFixed(fixedDecimal) : Number(n.toFixed(0)).toLocaleString() + '.' + Number(n.toString().slice(n.toString().indexOf('.')+1)).toLocaleString());