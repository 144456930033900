import {useWindowSize} from "@vueuse/core";
import {computed} from "vue";

export function useResponsive() {
    const {width} = useWindowSize()
    const isMobile = computed(() => {
        return width.value <= 768
    })

    return {isMobile}
}