export const userAgent = window.navigator.userAgent;
export const isIphone = userAgent.match(/iPhone/);
export const isAndroid = userAgent.match(/Android/);
export const isIpad = userAgent.match(/iPad/);
export const isMobileDevice = isIphone || isAndroid;

export const MY_VOLTALIS_APPLICATION_USER_AGENT = 'my-voltalis';

interface FlutterInAppWebView {
    callHandler<T = any, R = any>(type: string, arg: T): R | Promise<R>;
  }
  
  type WindowFlutterBinding = Window & typeof globalThis & {
    flutter_inappwebview: FlutterInAppWebView;
  }

export const isWebViewMobileApp = (window: Window): window is WindowFlutterBinding => {
    return "flutter_inappwebview" in window && userAgent.includes(MY_VOLTALIS_APPLICATION_USER_AGENT);
}