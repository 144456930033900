import phoneUtil, {PhoneNumberFormat} from "google-libphonenumber";

const phoneNumberUtil = phoneUtil.PhoneNumberUtil.getInstance();

export const addSpacePhoneNumber = (phoneNumber: string) => {
    try {
        return phoneNumberUtil.format(phoneNumberUtil.parseAndKeepRawInput(phoneNumber, "FR"), phoneNumber.includes('+') ? PhoneNumberFormat.INTERNATIONAL : PhoneNumberFormat.NATIONAL);
    } catch (e: any) {
        return phoneNumber;
    }
}

export const validatePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length === 0) return true;

    try {
        return phoneNumberUtil.isValidNumberForRegion(phoneNumberUtil.parse(phoneNumber, "FR"), "FR");
    } catch (e: any) {
        return false;
    }
}